import React, { Component, Fragment } from "react";
import Home from './home-component'

export default class TryAgain extends Component {
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm"> </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body alert-warning">
                                <h4 className="card-title">Strava Permissions</h4>
                                <div className="span4 offset4 alert alert-warning" role="alert">
                                    Please check all of the boxes when you authorize Observances.
                                </div>
                                <p className="span4 offset4 alert alert-warning">The <strong>Upload your activities from Observances to Strava</strong> permission is needed in order to make changes to the description of your activities.</p>
                                <p className="span4 offset4 alert alert-warning">The <strong>View data about your private activities</strong> permission is needed in order to update the description of activities that are private.  For example, you might have all of your activities uploaded as private then swtich them to public later.  Without this permission, Observances would be unable to update the description for your private activities as they are added.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm"></div>
                </div>
                <Home />
            </Fragment>
        )
    }
}