import React, { Component, Fragment } from "react";

export default class Terms extends Component {

    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm"> </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Terms</h4>
                                <p className="card-text">Your privacy is important to us.    </p>
                                <p className="card-text">You can stop the Observances integration with Strava at any time by revoking access on your Strava settings page.</p>
                                <p className="card-text">We can suspend your participation in the Observances integration at any time for any reason.</p>
                                <p className="card-text">Please contact us at info@observances.app if you have questions or would like help with Observances.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm"></div>
                </div>
            </Fragment>
        )
    }
}