import React from "react";
import {useState} from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
  Navigate
} from "react-router-dom";

import Settings from './components/settings-component'
import Navbar from './components/navbar-component'
import Privacy from './components/privacy-component'
import Features from './components/features-component'
import Terms from './components/terms-component'
import Home from './components/home-component'
import TryAgain from './components/tryagain-component'
import NotFound from './components/notfound-component'
import BetaComplete from './components/betacomplete-component'

export default class App extends React.Component {
  render() {
    return (

      <BrowserRouter>
        <div>
          <Navbar />
          <Routes>

            <Route path="/" element={<HomeOrSettings />} />
            <Route path="/settingsapp" element={<HomeOrSettings />} />
            <Route path="/home" element={<Home />} />
            <Route path="/settingsapp/home" element={<Home />} />

            <Route path="/features" element={<Features />} />
            <Route path="/settingsapp/features" element={<Features />} />

            <Route path="/privacy" element={<Privacy />} />
            <Route path="/settingsapp/privacy" element={<Privacy />} />

            <Route path="/terms" element={<Terms />} />
            <Route path="/settingsapp/terms" element={<Terms />} />

            <Route path="/settings" element={<Settings />}/>
            <Route path="/settingsapp/settings" element={<Settings />}/>

            {/* <Route path="/settings" element={<ErrorBoundary >
              <Settings />
            </ErrorBoundary>} />
            <Route path="/settingsapp/settings" element={<ErrorBoundary >
              <Settings />
            </ErrorBoundary>} /> */}

            <Route path="/tryagain" element={<TryAgain />} />
            <Route path="/settingsapp/tryagain" element={<TryAgain />} />

            <Route path="/betacomplete" element={<BetaComplete />} />
            <Route path="/settingsapp/betacomplete" element={<BetaComplete />} />

            <Route path="/notfound" element={<NotFound />} />
            <Route path="/settingsapp/notfound" element={<NotFound />} />

            
          </Routes>
        </div>
      </BrowserRouter>

    );
  }
}

function HomeOrSettings() {
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState(searchParams.get("userId"));



  if (userId) {
    localStorage.setItem('observances.resource.access', userId)
  }

  const token = localStorage.getItem('observances.resource.access')

  if (userId || token) {

    return <Navigate to="/settings" replace={true} />
  } else {
    return <Navigate to="/home" replace={true} />
  }

  
}
