import React, { Component, Fragment } from "react";
import poweredByStrava from '../images/api_logo_pwrdBy_strava_stack_white.png'
import poweredByWordnik from '../images/wordnik_badge_a1.png'
import providedByOpenWeather from '../images/OpenWeather-Master-Logo RGB.png'
import Authorize from "./authorize-components";
import constants from "./constants";

const poweredByWordnikAlternateText = "An image that says, Powered by Wordnik."
const poweredByStravaAlternateText = "An image that says, Powered by Strava."
const providedByOpenWeatherAlternateText = "An image that says, Weather data provided by OpenWeather"

export default class Home extends Component {
    render() {
        return (
            <Fragment>
                <Authorize />
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-sm"></div>
                        <div className="col-sm"></div>
                        <div className="col-sm"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm"> </div>
                        <div className="col-sm text-center">
                            <img
                                src={poweredByStrava}
                                alt={poweredByStravaAlternateText} /></div>
                        <div className="col-sm"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm">      </div>
                        <div className="col-sm text-center">
                            <img
                                src={poweredByWordnik}
                                alt={poweredByWordnikAlternateText} /></div>
                        <div className="col-sm"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm text-center">
                            <a href={constants.OPEN_WEATHER_URL}>
                                <img
                                    src={providedByOpenWeather}
                                    alt={providedByOpenWeatherAlternateText} />
                            </a>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}





