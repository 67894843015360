import React, { Component, Fragment } from "react";
import connectWithStrava from '../images/btn_strava_connectwith_orange.png'

const connectWithStravaAlternateText = "An image that says, Connect with Strava."
const stravaAuthorizationLink = "http://www.strava.com/oauth/authorize?client_id=33727&amp;response_type=code&amp;redirect_uri=https://observances.app/api/v2/tokens/exchange&amp;approval_prompt=force&amp;scope=activity:read_all,activity:write"

export default class Authorize extends Component {
    render() {
        return (
            <Fragment>
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-sm"> </div>
                        <div className="col-sm text-center">
                            <a href={stravaAuthorizationLink}>
                                <img src={connectWithStrava} alt={connectWithStravaAlternateText} />
                            </a>
                        </div>
                        <div className="col-sm"> </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}