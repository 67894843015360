import React, { Fragment } from "react";

function QuoteCard(props) {

    const { quote, refreshQuote, styles } = props
    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Quote</h4>
                    {quote}
                    <button style={styles} type='button' className='btn btn-outline-dark' onClick={refreshQuote}>Refresh</button>
                </div>
            </div>
        </Fragment>
    )
}

export default QuoteCard
