import React, { Fragment } from "react";
import styled from 'styled-components';

function ArtworkCard(props) {

    const { artworkUrl, artworkDescription, refreshArtwork, styles } = props
    return (
        <Fragment>
            <div className="card">
                {<ArtworkImage className="card-img-top  mx-auto" src={artworkUrl} alt={artworkDescription} ></ArtworkImage>}
                <div className="card-body">
                    <h4 className="card-title">Art</h4>
                    <p className='card-text'>
                        {artworkDescription}
                    </p>
                    <button style={styles} type='button' className='btn btn-outline-dark' onClick={refreshArtwork}>Refresh</button>
                </div>
            </div>
        </Fragment>
    )
}

const ArtworkImage = styled.img`
  width: 300px;
`;


export default ArtworkCard
