import React, { Component, Fragment } from "react";

export default class Privacy extends Component {
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm"> </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Privacy</h4>
                                <p className="card-text">We only store enough information required to add text to the description of your activity. </p>
                                <p className="card-text">That information is stored on secure servers with safeguards in place to prevent unauthorized access of any kind.</p>
                                <p className="card-text">When you stop participating in the service, the information is deleted.</p>
                                <p className="card-text">We don't share the information with any third parties.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm"></div>
                </div>
            </Fragment>
        )
    }

}

