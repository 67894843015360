import React, { Component, Fragment } from 'react';
import axios from "axios";
import Authorize from './authorize-components';
import jwt_decode from 'jwt-decode'
import WeatherBanner from './weather-banner-component';
import WeatherWidget from './weather-widget-component';
import styled from 'styled-components';
import ArtworkCard from './artwork-card-component';
import QuoteCard from './quote-card-component';
import UpdateCard from './update-card-component';

import constants from './constants';

const youNeedToAuthorize = "To see your settings or to configure your settings for the very first time, click the Connect with Strava button to authorize Observances."

class Settings extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.refreshWeather = this.refreshWeather.bind(this);
        this.refreshForecast = this.refreshForecast.bind(this);
        this.refreshQuote = this.refreshQuote.bind(this);
        this.refreshArtwork = this.refreshArtwork.bind(this);

        this.state = {
            loadingSettings: true,
            updatingSettings: false,
            error: null,
            settings: null,
            styles: {
                display: 'block',
                margin: '0 auto'
            }
        }
    }

    refreshWeather() {
        const token = localStorage.getItem('observances.resource.access')
        var athleteId

        if (token) {
            const decodedToken = jwt_decode(token)
            athleteId = decodedToken.sub
        }

        if (athleteId) {
            var weatherConfig = {
                method: 'get',
                url: `/api/v1/athletes/${athleteId}/weather`,
                headers: {
                    'authorization': `Bearer ${token}`
                }
            };

            axios(weatherConfig)
                .then(weatherResponse => {

                    const weatherData = {
                        dt: weatherResponse.data.current.dt,
                        temp: weatherResponse.data.current.temp,
                        feels_like: weatherResponse.data.current.feels_like,
                        humidity: weatherResponse.data.current.humidity,
                        icon: weatherResponse.data.current.weather[0].icon,
                        desc: weatherResponse.data.current.weather[0].description,
                        wind: weatherResponse.data.current.wind_speed
                    }

                    this.setState({ weatherData: weatherData, loadingWeather: false })
                })
                .catch(error => {
                    console.log('weather endpoint failure')
                    this.setState({ weatherError: 'No weather today.', loadingWeather: false })
                })
        }

    }

    refreshForecast() {
        const token = localStorage.getItem('observances.resource.access')
        var athleteId

        if (token) {
            const decodedToken = jwt_decode(token)
            athleteId = decodedToken.sub
        }

        if (athleteId) {
            var weatherConfig = {
                method: 'get',
                url: `/api/v1/athletes/${athleteId}/forecast`,
                headers: {
                    'authorization': `Bearer ${token}`
                }
            };

            axios(weatherConfig)
                .then(weatherResponse => {

                    const forecastData = weatherResponse.data.list.map((data) => ({
                        dt: data.dt,
                        temp: data.main.temp,
                        temp_min: data.main.temp_min,
                        temp_max: data.main.temp_max,
                        feels_like: data.main.feels_like,
                        humidity: data.main.humidity,
                        icon: data.weather[0].icon,
                        desc: data.weather[0].description,
                        wind: data.wind.speed,
                    }));

                    this.setState({ forecastData: forecastData, loadingForecast: false })
                })
                .catch(error => {
                    console.log('weather endpoint failure')
                    this.setState({ weatherForecast: 'No forecast today.', loadingForecast: false })
                })
        }

    }

    refreshQuote() {
        const token = localStorage.getItem('observances.resource.access')
        var athleteId

        if (token) {
            const decodedToken = jwt_decode(token)
            athleteId = decodedToken.sub
        }

        if (athleteId) {
            var quoteConfig = {
                method: 'get',
                url: `/api/v1/athletes/${athleteId}/quote`,
                headers: {
                    'authorization': `Bearer ${token}`
                }
            };

            axios(quoteConfig)
                .then(quoteResponse => {
                    const quote = quoteResponse.data

                    this.setState({ quote: quote, loadingQuote: false })
                })
                .catch(error => {
                    console.log('quote endpoint failure')
                    this.setState({ quoteError: 'No quote today.', loadingQuote: false })
                })
        }

    }

    refreshArtwork() {
        const token = localStorage.getItem('observances.resource.access')
        var athleteId

        if (token) {
            const decodedToken = jwt_decode(token)
            athleteId = decodedToken.sub
        }

        if (athleteId) {
            var artworkConfig = {
                method: 'get',
                url: `/api/v1/athletes/${athleteId}/artwork`,
                headers: {
                    'authorization': `Bearer ${token}`
                }
            };

            axios(artworkConfig)
                .then(artworkResponse => {
                    const artworkUrl = artworkResponse.data.imageUrl
                    const artworkDescription = artworkResponse.data.artDescription

                    console.log(artworkUrl)
                    console.log(artworkDescription)

                    this.setState({ artworkUrl: artworkUrl, artworkDescription: artworkDescription })
                })
                .catch(error => {
                    console.log('artwork endpoint failure')
                    this.setState({ quoteError: 'No artwork today.', loadingQuote: false })
                })
        }

    }

    onSubmit({ formData }) {
        this.setState({ updatingSettings: true, settings: formData })

        const token = localStorage.getItem('observances.resource.access')
        var athleteId

        if (token) {
            const decodedToken = jwt_decode(token)
            athleteId = decodedToken.sub
        }


        var put = {
            method: 'put',
            url: `/api/v1/athletes/${athleteId}/settings`,
            headers: {
                'authorization': `Bearer ${token}`
            },
            data: formData
        };

        axios(put)
            .then(response => {
                this.setState({ loadingSettings: false, updatingSettings: false })


            })
            .catch(error => {
                this.setState({ loadingSettings: false, error: error.toString() })
            })

        var get = {
            method: 'get',
            url: `/api/v1/athletes/${athleteId}/settings`,
            headers: {
                'authorization': `Bearer ${token}`
            }
        };
        axios(get)
            .then(response => {
                this.setState({ settings: response.data, loadingSettings: false })

            })
            .catch(error => {
                this.setState({ loadingSettings: false, error: error.toString() })
            })

        this.refreshWeather()
        this.refreshForecast()
        this.refreshQuote()
        this.refreshArtwork()



    }

    componentDidMount() {
        this.setState({ loadingSettings: true, loadingWeather: true })
        const token = localStorage.getItem('observances.resource.access')

        var athleteId

        if (token) {
            const decodedToken = jwt_decode(token)
            athleteId = decodedToken.sub
        }

        if (!athleteId) {
            this.setState({ loadingSettings: false, error: youNeedToAuthorize })
        } else {

            var config = {
                method: 'get',
                url: `/api/v1/athletes/${athleteId}/settings`,
                headers: {
                    'authorization': `Bearer ${token}`
                }
            };

            axios(config)
                .then(response => {
                    this.setState({ settings: response.data, loadingSettings: false, loadingWeather: true })

                })
                .catch(error => {
                    this.setState({ loadingSettings: false, error: youNeedToAuthorize })
                })

            this.refreshWeather()
            this.refreshForecast()
            this.refreshQuote()
            this.refreshArtwork()


        }
    }

    render() {
        const {
            error,
            loadingSettings,
            updatingSettings,
            settings,
            loadingWeather,
            weatherError,
            weatherData,
            loadingForecast,
            forecastData,
            forecastError,
            styles,
            quote,
            loadingQuote,
            quoteError,
            artworkDescription,
            artworkUrl
        } = this.state;

        if (error) {
            return (
                <Fragment>
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                    <Authorize />
                </Fragment>
            );
        }

        if (loadingSettings || loadingWeather) {
            return (
                <div className="alert alert-info" role="alert">
                    Loading settings...
                </div>

            )
        }

        return (
            <Fragment>
                {updatingSettings && <div className="alert alert-info" role="alert">
                    Updating settings...
                </div>}
                <div className='row'>
                    <div className="col-sm"></div>
                    <div className="col-sm">
                        <ArtworkCard
                            artworkUrl={artworkUrl}
                            artworkDescription={artworkDescription}
                            styles={styles}
                            refreshArtwork={this.refreshArtwork} ></ArtworkCard>
                    </div>
                    <div className="col-sm"></div>
                </div>
                <div className="row">
                    <div className="col-sm"></div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Weather</h4>
                                {<WeatherBanner
                                    forecastNow={weatherData}
                                    unit='imperial'
                                    locale='en-us'
                                    providedBy={constants.WEATHER_PROVIDED_BY}></WeatherBanner>}
                                    
                                <button
                                    style={styles}
                                    type='button'
                                    className='btn btn-outline-dark'
                                    onClick={this.refreshWeather}>Refresh</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm"></div>
                </div>
                <div className="row">
                    <div className="col-sm"></div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Forecast</h4>
                                {<WeatherWidget
                                    config={{
                                        unit: 'imperial',
                                        locale: 'en-us', 
                                        providedBy: constants.WEATHER_PROVIDED_BY
                                    }}
                                    forecast={forecastData}
                                ></WeatherWidget>}
                                <button
                                    style={styles}
                                    type='button'
                                    className='btn btn-outline-dark'
                                    onClick={this.refreshForecast}>Refresh</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm"></div>
                </div>
                <div className="row">
                    <div className="col-sm"> </div>
                    <div className="col-sm">
                        <QuoteCard
                            quote={quote}
                            styles={styles}
                            refreshQuote={this.refreshQuote}> </QuoteCard>
                    </div>
                    <div className="col-sm"></div>
                </div>
                <div className="row">
                    <div className="col-sm"></div>
                    <div className="col-sm">
                        <UpdateCard
                            settings={settings}
                            onSubmit={this.onSubmit}></UpdateCard>
                    </div>
                    <div className="col-sm"></div>
                </div>
            </Fragment >
        )
    }
}

const ArtworkImage = styled.img`
  width: 300px;
`;

export default Settings;