import React, { Component, Fragment } from "react";
import Home from './home-component'

export default class NotFound extends Component {
    render() {
        return (
            <Fragment>
                <React.Fragment>
                    <div className="alert alert-warning" role="alert">
                        Oops!  The page you requested was not found--not to say that it's lost.  :)
                    </div>
                    <Home/>
                </React.Fragment>
            </Fragment>
        )
    }
}