import React, { Fragment } from "react";
import Form from "@rjsf/bootstrap-4";
import validator from '@rjsf/validator-ajv8';

function UpdateCard(props) {
    const settingsSchema = {
        "type": "object",
        "properties": {
            "features": {
                "type": "array",
                "title": "Features",
                "description": "If you choose more than one feature, one of them will be chosen at random when your activity is uploaded to Strava.",
                "minItems": 1,
                "items": {
                    "type": "string",
                    "enum": [
                        "random-quote",
                        "random-wordnik",
                        "random-artic-art",
                        "smithsonian-history_culture",
                        "smithsonian-art_design",
                        "smithsonian-science_technology",
                        "random-met-art-arms-and-armor",
                        "random-met-art-asian",
                        "random-met-art-egyptian",
                        "random-met-art-european-paintings",
                    ],
                    "enumNames": [
                        "A Random Quote",
                        "A Random Word Defined by Wordnik",
                        "A Random Artwork from The Art Institute of Chicago®",
                        "A Random History & Culture Item from The Smithsonian",
                        "A Random Art & Design Item from The Smithsonian",
                        "A Random Science & Technology Item from The Smithsonian",
                        "A Random Artwork from the Met's Art and Armor Collection",
                        "A Random Artwork from the Met's Asian Collection",
                        "A Random Artwork from the Met's Egyptian Collection",
                        "A Random Artwork from the Met's European Paintings Collection",
                    ]
                },
                "uniqueItems": true
            },
            "lat": {
                "title": "Latitude",
                "type": "number",
                "minimum": -90,
                "maximum": 90
            },
            "lon": {
                "title": "Longitude",
                "type": "number",
                "minimum": -180,
                "maximum": 180
            }
    
        }
    };
    
    const uiSchema = {
        "features": {
            "ui:widget": "checkboxes"
        }
    }

    const { settings, onSubmit } = props
    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Settings</h4>
                    <Form
                        schema={settingsSchema}
                        uiSchema={uiSchema}
                        formData={settings}
                        validator={validator}
                        onSubmit={onSubmit} />
                </div>
            </div>
        </Fragment>
    )
}

export default UpdateCard