import React, { Fragment, Component } from "react";
import { Link } from 'react-router-dom'

export default class Navbar extends Component {
    render() {
        return (
            <Fragment>
                <nav className="navbar navbar-expand-lg navbar-light"><a className="navbar-brand" href="/">Observances (Beta)</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto"></ul>
                        <ul className="navbar-nav float-right">
                            <li><Link className="nav-link" to="/home">Home</Link></li>
                            <li><Link className="nav-link" to="/features">Features</Link></li>
                            <li><Link className="nav-link" to="/privacy">Privacy</Link></li>
                            <li><Link className="nav-link" to="/terms">Terms</Link></li>
                            <li><Link className="nav-link" to="/settings">Settings</Link></li>
                        </ul>
                    </div>
                </nav>
            </Fragment>
        )

    }
}