import React, { Component, Fragment } from "react";

export default class Features extends Component {
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm"> </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Random Word</h4>
                                <p className="card-text">When you authorize the app, we'll add a random word to any newly added Strava activities.</p>
                                <p className="card-text">We'll even add a definition if we can find one.</p>
                                <p className="card-text">More activities?  More words!</p>
                            </div>
                            <div className="content">
                                <p>📚 petalous (adjective)
                                    <br></br>
                                    -Having petals.
                                </p>
                                <p>
                                    from The American Heritage® Dictionary of the English Language, 5th Edition.
                                    <br></br>
                                    <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.wordnik.com/words/petalous">https://www.wordnik.com/words/petalous</a>
                                    <br></br>
                                    via observances.app
                                    <br></br>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm"></div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm"> </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Random Quote (Premium)</h4>
                                <p className="card-text">When you authorize the app and configure this premium feature, we'll add a random quote to any newly added Strava activities.</p>
                                <p>                       </p>
                            </div>
                            
                            <p>
                                ☀ Three things in human life are important. The first is to be kind. The second is to be kind. The third is to be kind. - Henry James 
                                <br></br>
                                via observances.app
                            </p>
                        </div>
                    </div>
                    <div className="col-sm"> </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm"> </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Random Artwork (Premium)</h4>
                                <p className="card-text">When you authorize the app and configure this premium feature, we'll update any newly added activity with a link to an image in the public domain from the collections of the Art Institute of Chicago® or the Metropolitan Museum of New York.  If you choose something from the Smithsonian, you might get a link to an item from The National Portrait Gallery, the Library of Congress, the National Zoo, or one of the Smithsonian's museums, libraries, archives, or research centers. So cool. </p>
                                <p className="card-text">There will generally be a short description including the artist's name and the title of the work along with the link.</p>
                                <p className="card-text">It's like a trip to the Museum after every swim, bike, or run!</p>
                                <p>                       </p>
                            </div>
                            <div className="content">
                                <p>🎨 Stacks of Wheat (End of Summer)
                                    <br></br>
                                    Claude Monet
                                    <br></br>
                                    Painting and Sculpture of Europe
                                    <br></br>
                                    The Art Institute of Chicago®
                                    <br></br>
                                    <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.artic.edu/iiif/2/a38e2828-ec6f-ece1-a30f-70243449197b/full/843,/0/default.jpg">https://www.artic.edu/iiif/2/a38e2828-ec6f-ece1-a30f-70243449197b/full/843,/0/default.jpg</a>
                                    </p>
                                    
                            </div>
                        </div>
                    </div>
                    <div className="col-sm"> </div>
                </div>
            </Fragment>
        )
    }
}