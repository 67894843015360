import React, { Component, Fragment } from "react";

export default class BetaComplete extends Component {
    render() {
        return (
            <Fragment>
                <React.Fragment>
                    <div className="alert alert-info" role="alert">
                        The Observances Beta program is full.  We are no longer accepting new athletes.  Thank you for your interest!  Please check back again at a later date.
                    </div>
                </React.Fragment>
            </Fragment>
        )
    }
}